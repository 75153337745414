import React, { Component } from "react";
import logo from "./skyway.png";
// import { AmplifySignOut } from "@aws-amplify/ui-react";

class TopMenu extends Component {
  state = {
    // loggedIn: this.props.loggedIn,
    imageUrl: logo,
  };

  render() {
    return (
      <nav className="navbar fixed-top navbar-light bg-dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img className="Menu-logo" src={this.state.imageUrl} alt="" />
          </a>
          <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="/latest">
                <h4>
                  <span className="badge bg-warning text-body">Latest</span>
                </h4>
              </a>
            </li>
          </ul>
          {/* {this.displayLogInOut()} */}
        </div>
      </nav>
    );
  }

  //   displayLogInOut() {
  //     if (this.state.loggedIn) {
  //       return <AmplifySignOut buttonText="Sign Out"></AmplifySignOut>;
  //     } else {
  //       return <AmplifySignOut buttonText="Sign Out"></AmplifySignOut>;
  //     }
  //   }
}

export default TopMenu;
