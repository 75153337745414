import React, { Component } from "react";
import parks_api from "../api/parks";

class Latest extends Component {
  state = { dlData: null, dcaData: null };

  componentDidMount() {
    this.getWaittimes();
  }

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <h3>Disneyland</h3>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Posted Standby</th>
                <th scope="col">Status</th>
                <th scope="col">Avg</th>
                <th scope="col">Max Avg</th>
              </tr>
            </thead>
            <tbody>{this.state.dlData}</tbody>
          </table>
          <h3>DCA</h3>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Posted Standby</th>
                <th scope="col">Status</th>
                <th scope="col">Avg</th>
                <th scope="col">Max Avg</th>
              </tr>
            </thead>
            <tbody>{this.state.dcaData}</tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }

  async getWaittimes() {
    const { data: dlresponse } = await parks_api.getDLWaittimes();
    const dlTimes = dlresponse.map((d) => (
      <tr>
        <td>{d["name"]}</td>
        <td>{d["waitTime"]}</td>
        <td>{d["status"]}</td>
        <td>{d["avg_waittime"]}</td>
        <td>{d["max_avg_waittime"]}</td>
      </tr>
    ));
    const { data: dcaresponse } = await parks_api.getDCAWaittimes();
    const dcaTimes = dcaresponse.map((d) => (
      <tr>
        <td>{d["name"]}</td>
        <td>{d["waitTime"]}</td>
        <td>{d["status"]}</td>
        <td>{d["avg_waittime"]}</td>
        <td>{d["max_avg_waittime"]}</td>
      </tr>
    ));
    this.setState({ dlData: dlTimes });
    this.setState({ dcaData: dcaTimes });
  }
}

export default Latest;
