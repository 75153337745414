import client from "./client";

const endpoint = "parks/";
const disneyland = "dl";
const dca = "dca";
const waittimes = "/waittimes";
const calendar = "/calendar";

const getParksAvg = (date) => client.get(endpoint + "averages/day/" + date);

const getPark = () => client.get(endpoint + "/" + disneyland);

const getDLWaittimes = () => {
  return client.get(endpoint + disneyland + waittimes);
};

const getDCAWaittimes = () => {
  return client.get(endpoint + dca + waittimes);
};

const getCalendar = () => client.get(endpoint + "/" + disneyland + calendar);

export default {
  getDLWaittimes,
  getDCAWaittimes,
  getParksAvg,
};
