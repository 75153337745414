import React, { Component } from "react";
import TopMenu from "./topMenu";
import AttractionDay from "./attractionDay";
import Latest from "./latest";
import { Route } from "react-router-dom";

class RootView extends Component {
  //   state = { loggedIn: true, admin: true };

  //   handleLogInOut = () => {
  //     this.setState({ loggedIn: !this.state.loggedIn });
  //   };

  render() {
    return (
      <React.Fragment>
        <TopMenu />
        <div style={{ paddingTop: 85 }}>
          {/* Primary Yield */}
          <Route path="/latest" component={Latest} />
          <Route path="/" exact component={AttractionDay} />
        </div>
      </React.Fragment>
    );
  }
}

export default RootView;
