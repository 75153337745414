import client from "./client";

const endpoint = "attractions/";
const day = "/day/";
// const calendar = "/calendar";

// const getParks = () => client.get(endpoint);

// const getPark = () => client.get(endpoint + "/" + disneyland);

const getAttractions = () => {
  return client.get(endpoint);
};

const getAttractionDay = (facility_id, date) => {
  return client.get(endpoint + facility_id + day + date);
};

export default {
  getAttractionDay,
  getAttractions,
};
