import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import attractions_api from "../api/attractions";
import parks_api from "../api/parks";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const options = {
  lineTension: 0.2,
  scales: {
    y: {
      min: 0,
      suggestedMax: 60,
    },
    yboolean: {
      min: 0,
      max: 1,
      display: false,
    },
    x: {
      type: "time",
      time: {
        unit: "hours",
        displayFormats: {
          hours: "HH:mm",
        },
      },
    },
  },
};

const aOptions = {
  lineTension: 0.2,
  scales: {
    yAvg: {},
    x: {
      type: "time",
      time: {
        unit: "hours",
        displayFormats: {
          hours: "HH:mm",
        },
      },
    },
  },
};

class AttractionDay extends Component {
  state = {
    date: moment().format("YYYY-MM-DD"),
    facility_ids: "",
    attractions: [],
    chartKey: "",
    data: {
      labels: [],
      datasets: [
        {
          label: "Attraction",
          data: [],
        },
      ],
    },
    avgData: {
      labels: [],
      datasets: [
        {
          label: "Averages",
          data: [],
        },
      ],
    },
  };

  componentDidMount() {
    this.getAttractions();
    this.updateAvgChart(this.state.date);
  }

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div class="alert alert-warning" role="alert">
            Data for July 3 and July 4 encountered some bugs. Corrected data
            will be added soon.
          </div>
          <div className="card">
            <div className="card-header">Attraction Day</div>
            <div className="card-body">
              <div className="row">
                <div className="col-4">
                  {this.newDatePicker(this.state.date)}
                </div>
                <div className="col-2"></div>
                <div className="col-3">
                  <div className="dropdown">
                    <button
                      className="btn btn-outline-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Add
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {this.state.attractions}
                    </ul>
                  </div>
                </div>
                <div className="col-3">
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => this.resetChart()}
                  >
                    clear
                  </button>
                </div>
              </div>
            </div>
            <Line
              key={this.state.chartKey}
              data={this.state.data}
              options={options}
            />
          </div>
        </div>
        <br />
        <div className="card">
          <div className="card-header">Park Averages - {this.state.date}</div>
          <div className="card-body">
            <Line
              key={this.state.chartKey}
              data={this.state.avgData}
              options={aOptions}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  resetChart() {
    const d = {
      labels: [],
      datasets: [
        {
          label: "Attraction",
          data: [],
        },
      ],
    };
    this.setState({ facility_ids: "" });
    this.setState({ data: d });
    let ck = Math.random().toString(36).substring(7);
    this.setState({ chartKey: ck });
  }

  async updateChart(facility_id, date) {
    let facility_ids = this.state.facility_ids;
    if (facility_ids.length === 0) {
      facility_ids = facility_id;
    } else if (facility_id !== facility_ids) {
      facility_ids = facility_ids + "," + facility_id;
    }
    const { data: response } = await attractions_api.getAttractionDay(
      facility_ids,
      date
    );
    var d = this.state.data;
    console.log(response);
    d.datasets = response;
    this.setState({ facility_ids });
    this.setState({ data: d });
    let ck = Math.random().toString(36).substring(7);
    this.setState({ chartKey: ck });
  }

  async getAttractions() {
    const { data: response } = await attractions_api.getAttractions();
    const attractionListItems = response.map((d) => (
      <li key={d["facility_id"]}>
        <a
          className="dropdown-item"
          href="#"
          onClick={() => this.updateChart(d["facility_id"], this.state.date)}
        >
          {d.name}
        </a>
      </li>
    ));
    this.setState({ attractions: attractionListItems });
  }

  updateDate(date) {
    console.log(date);
    this.setState({ date: date });
    this.updateAvgChart(date);
    let ck = Math.random().toString(36).substring(7);
    this.setState({ chartKey: ck });
    if (this.state.facility_ids !== "") {
      this.updateChart(this.state.facility_ids, date);
    }
  }

  async updateAvgChart(date) {
    const { data: response } = await parks_api.getParksAvg(date);
    var d = this.state.avgData;
    console.log(response);
    d.datasets = response;
    let ck = Math.random().toString(36).substring(7);
    this.setState({ chartKey: ck });
  }

  newDatePicker(d) {
    return (
      <DatePicker
        selected={new Date(moment(this.state.date))}
        minDate={new Date(moment("2021-06-15"))}
        maxDate={new Date(moment())}
        onChange={(date) => this.updateDate(moment(date).format("YYYY-MM-DD"))}
      />
    );
  }
}

export default AttractionDay;
